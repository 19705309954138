export const neighborhoods = 
  {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#cec812",
                "fill-opacity": 0.5,
                "name": "Lower East Side",
                "progress": 275,
                "total": 1638
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.996042,
                            40.71629900000001
                        ],
                        [
                            -74.00154557079077,
                            40.709774984585444
                        ], [
                            -73.999335,
                            40.70808399999999
                        ],
                        [
                            -73.997662,
                            40.708832
                        ],
                        [
                            -73.988649,
                            40.710011
                        ],
                        [
                            -73.98848300000002,
                            40.709132999999994
                        ],
                        [
                            -73.981289,
                            40.70985700000001
                        ],
                        [
                            -73.981375,
                            40.710296000000014
                        ],
                        [
                            -73.977921,
                            40.710589
                        ],
                        [
                            -73.976397,
                            40.712166
                        ],
                        [
                            -73.973648250103,
                            40.71844362994627
                        ],
                        [
                            -73.99248406291008,
                            40.7242249795171
                        ],
                        [
                            -73.996042,
                            40.71629900000001
                        ]
                    ]
                ]
            },
            "id": "468bb6165920447a5a01b3745e2cf05c"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#061aac",
                "fill-opacity": 0.5,
                "name": "SoHo",
                "progress": 300,
                "total": 306
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.010859,
                            40.72908
                        ],
                        [
                            -74.011116,
                            40.726022
                        ],
                        [
                            -73.99882,
                            40.717208
                        ],
                        [
                            -73.996042,
                            40.716297
                        ],
                        [
                            -73.99248406291008,
                            40.7242249795171
                        ],
                        [
                            -73.99541974067688,
                            40.72512776185051
                        ],
                        [
                            -73.99691104888916,
                            40.725437240845096
                        ],
                        [
                            -74.00287359952927,
                            40.728494602539605
                        ],
                        [
                            -74.010859,
                            40.72908
                        ]
                    ]
                ]
            },
            "id": "4be29a7ae7f0ded1f15c68f2b3d621cb"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#ffdd00",
                "fill-opacity": 0.5,
                "name": "Upper East Side",
                "progress": 200,
                "total": 1497
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.935499,
                            40.791264
                        ],
                        [
                            -73.95387902855873,
                            40.79882413456096
                        ],
                        [
                            -73.97759914398193,
                            40.76621717656102
                        ],
                        [
                            -73.958802,
                            40.758277
                        ],
                        [
                            -73.942365,
                            40.775536
                        ],
                        [
                            -73.943438,
                            40.783238
                        ],
                        [
                            -73.940477,
                            40.78483
                        ],
                        [
                            -73.935499,
                            40.791264
                        ]
                    ]
                ]
            },
            "id": "4d3427f4d35dbb62c72d3174f6e2f286"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#aeff00",
                "fill-opacity": 0.5,
                "name": "Alphabet City",
                "progress": 600,
                "total": 667
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.98854926228523,
                            40.72301447478376
                        ],
                        [
                            -73.97365,
                            40.71844399999999
                        ],
                        [
                            -73.971721,
                            40.726839000000005
                        ],
                        [
                            -73.98255217820407,
                            40.73137372473564
                        ],
                        [
                            -73.98854926228523,
                            40.72301447478376
                        ]
                    ]
                ]
            },
            "id": "5b7aa619a7b1ab4c227b20638998d122"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#0affb6",
                "fill-opacity": 0.5,
                "name": "Harlem",
                "progress": 20,
                "total": 2076
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.9523,
                            40.835486
                        ],
                        [
                            -73.971033,
                            40.805883
                        ],
                        [
                            -73.935499,
                            40.791264
                        ],
                        [
                            -73.92919,
                            40.79539
                        ],
                        [
                            -73.929233,
                            40.801595
                        ],
                        [
                            -73.934469,
                            40.809196
                        ],
                        [
                            -73.93464,
                            40.827952
                        ],
                        [
                            -73.9523,
                            40.835486
                        ]
                    ]
                ]
            },
            "id": "65434a8beb07850226a5ca82a759fb9a"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#0de732",
                "fill-opacity": 0.5,
                "name": "Greenwich Village",
                "progress": 500,
                "total": 2355
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.009233,
                            40.74255
                        ],
                        [
                            -74.010472,
                            40.73929
                        ],
                        [
                            -74.010858,
                            40.729079
                        ],
                        [
                            -74.00287494063377,
                            40.728494602539605
                        ],
                        [
                            -73.996911,
                            40.725437
                        ],
                        [
                            -73.9954187348485,
                            40.72512776185051
                        ],
                        [
                            -73.98854926228523,
                            40.72301447478376
                        ],
                        [
                            -73.98255217820406,
                            40.731374232864816
                        ],
                        [
                            -74.009233,
                            40.74255
                        ]
                    ]
                ]
            },
            "id": "88ca8e1689d2526e0f591b918dc1bf2f"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".3",
                "fill": "#00b3d6",
                "fill-opacity": 0.5,
                "name": "Chelsea",
                "progress": 345,
                "total": 772
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.005195,
                            40.757118
                        ],
                        [
                            -74.005494,
                            40.757245
                        ],
                        [
                            -74.007672,
                            40.754271
                        ],
                        [
                            -74.008734,
                            40.750524
                        ],
                        [
                            -74.008047,
                            40.748508
                        ],
                        [
                            -74.009233,
                            40.74255
                        ],
                        [
                            -73.99683628231287,
                            40.73735794697715
                        ],
                        [
                            -73.9877774566412,
                            40.74978171395176
                        ],
                        [
                            -74.005195,
                            40.757118
                        ]
                    ]
                ]
            },
            "id": "aa5ee24ba1d67eff9a603366ac11a7a0"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#ffa305",
                "fill-opacity": 0.5,
                "name": "Flatiron + Kips Bay",
                "progress": 450,
                "total": 1477
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.971977,
                            40.743129
                        ],
                        [
                            -73.987777,
                            40.749782
                        ],
                        [
                            -73.99683628231287,
                            40.737358201018885
                        ],
                        [
                            -73.971719,
                            40.726835
                        ],
                        [
                            -73.971505,
                            40.72947
                        ],
                        [
                            -73.97365,
                            40.731584
                        ],
                        [
                            -73.974552,
                            40.736494
                        ],
                        [
                            -73.972749,
                            40.735681
                        ],
                        [
                            -73.971977,
                            40.743129
                        ]
                    ]
                ]
            },
            "id": "ab66d0d754c52aa7fe213590bc0fbaf3"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#00a331",
                "name": "Financial District",
                "progress": 300,
                "fill-opacity": 0.5,
                "total": 581
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.01729449629784,
                            40.7155090573459
                        ],
                        [
                            -74.019468,
                            40.70601
                        ],
                        [
                            -74.01906,
                            40.704773
                        ],
                        [
                            -74.017515,
                            40.703033
                        ],
                        [
                            -74.016249,
                            40.701683
                        ],
                        [
                            -74.015219,
                            40.700886
                        ],
                        [
                            -74.013481,
                            40.700398
                        ],
                        [
                            -74.011142,
                            40.700869
                        ],
                        [
                            -74.009147,
                            40.702008
                        ],
                        [
                            -74.00408,
                            40.705154
                        ],
                        [
                            -74.002892,
                            40.704062
                        ],
                        [
                            -74.002659,
                            40.704216
                        ],
                        [
                            -74.003874,
                            40.705296
                        ],
                        [
                            -74.003351,
                            40.705624
                        ],
                        [
                            -74.002168,
                            40.704599
                        ],
                        [
                            -74.001975,
                            40.704733
                        ],
                        [
                            -74.002463,
                            40.705188
                        ],
                        [
                            -74.002076,
                            40.705416
                        ],
                        [
                            -74.00147,
                            40.704867
                        ],
                        [
                            -74.000569,
                            40.705428
                        ],
                        [
                            -74.001417,
                            40.706197
                        ],
                        [
                            -74.000982,
                            40.70642
                        ],
                        [
                            -74.001422,
                            40.70686
                        ],
                        [
                            -73.999335,
                            40.708084
                        ],
                        [
                            -74.00154557079077,
                            40.70977473043841
                        ],
                        [
                            -74.011437,
                            40.714947
                        ],
                        [
                            -74.01729449629784,
                            40.7155090573459
                        ]
                    ]
                ]
            },
            "id": "cff959414c22c71a63b8d01be829ff87"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#0008ff",
                "fill-opacity": 0.5,
                "name": "Washington Heights",
                "progress": 60,
                "total": 817
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.920114,
                            40.858048
                        ],
                        [
                            -73.932677,
                            40.869046
                        ],
                        [
                            -73.952279,
                            40.835469
                        ],
                        [
                            -73.93464,
                            40.827936
                        ],
                        [
                            -73.935413,
                            40.833683
                        ],
                        [
                            -73.920114,
                            40.858048
                        ]
                    ]
                ]
            },
            "id": "d1e5a3ef29f61ff295bc405598a88a92"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#ff05f7",
                "fill-opacity": 0.5,
                "name": "Inwood",
                "progress": 30,
                "total": 83
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.923397,
                            40.877439
                        ],
                        [
                            -73.926594,
                            40.877601
                        ],
                        [
                            -73.92904,
                            40.875833
                        ],
                        [
                            -73.932677,
                            40.869047
                        ],
                        [
                            -73.920092,
                            40.858032
                        ],
                        [
                            -73.911166,
                            40.868807
                        ],
                        [
                            -73.91108,
                            40.869164
                        ],
                        [
                            -73.910994,
                            40.869294
                        ],
                        [
                            -73.910479,
                            40.872279
                        ],
                        [
                            -73.912153,
                            40.873675
                        ],
                        [
                            -73.923397,
                            40.877439
                        ]
                    ]
                ]
            },
            "id": "dd646d22cd00b4a6b439e636bff2480a"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#a600ff",
                "fill-opacity": 0.5,
                "name": "Upper West Side",
                "progress": 250,
                "total": 1831
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.971033,
                            40.805883
                        ],
                        [
                            -73.994507,
                            40.773359
                        ],
                        [
                            -73.97759914398193,
                            40.76621717656102
                        ],
                        [
                            -73.953877,
                            40.798826
                        ],
                        [
                            -73.971033,
                            40.805883
                        ]
                    ]
                ]
            },
            "id": "eab0aefa4630a72e5dd37f044f05e43d"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#ffbde3",
                "fill-opacity": 0.5,
                "name": "Tribeca",
                "progress": 400,
                "total": 1571
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.011117,
                            40.72602
                        ],
                        [
                            -74.012961,
                            40.718282
                        ],
                        [
                            -74.016673,
                            40.718656
                        ],
                        [
                            -74.01729449629784,
                            40.7155090573459
                        ],
                        [
                            -74.011437,
                            40.714947
                        ],
                        [
                            -74.001546,
                            40.709775
                        ],
                        [
                            -73.996043,
                            40.716298
                        ],
                        [
                            -73.998823,
                            40.717208
                        ],
                        [
                            -74.011117,
                            40.72602
                        ]
                    ]
                ]
            },
            "id": "eb1ce0a589b0d7a67aa4533308c54be7"
        },
        {
            "type": "Feature",
            "properties": {
                "stroke": "#000000",
                "stroke-width": 1,
                "stroke-opacity": ".5",
                "fill": "#ff0000",
                "fill-opacity": 0.5,
                "name": "Midtown",
                "progress": 600,
                "total": 3367
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.994508,
                            40.773359
                        ],
                        [
                            -74.005494,
                            40.757243
                        ],
                        [
                            -73.971977,
                            40.743127
                        ],
                        [
                            -73.958802,
                            40.758278
                        ],
                        [
                            -73.994508,
                            40.773359
                        ]
                    ]
                ]
            },
            "id": "ec5dcf37eea581032787d2017e5ef57f"
        }
    ]
}

export default neighborhoods;