import ScrollDown from "../../images/scrollDown.svg";

export const HeaderData = {
  title: {
    one: "Help us to",
    two: "Explore the city",
  },
  subtitle: {
    one: "Virtually explore city streets to find and",
    two: "label storefront accessibility target",
  },
  button: "explore",
  icon: ScrollDown,
};
